import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, IconButton, InputBase, Button, Typography, TextField, Container, Grid, Box } from '@mui/material'; // Grouped MUI components
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

// Grouping Material-UI Icons
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PinDropIcon from '@mui/icons-material/PinDrop';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

// Importing images
import Hero from '../Assets/BackGroundImg/Home.png';
import vegis from '../Assets/images/vegis.webp';
import Logo from '../Assets/images/Kisaanstarlogo1.webp';
import Services1 from '../Assets/images/services1.webp';
import Services2 from '../Assets/images/Services2.webp';
import Services3 from '../Assets/images/Services3.webp';
import Service4 from '../Assets/images/Services4.webp';
import Advisor from '../Assets/images/Advisor.webp';
import Advisor1 from '../Assets/images/Advisor1.webp';

// Other imports
import 'bootstrap/dist/css/bootstrap.min.css';
import HoverCarousel from 'hover-carousel';
import ProductList from './Productlist';

function Home() {
    const images = [
        Services1,
        Services2,
        Services3,
        Service4,
        Services1,
    ];

    const [scrolled, setScrolled] = useState(false);

    // Counter Component with Animation
    const Counter = ({ title, targetNumber, percentage, color }) => {
        const [currentNumber, setCurrentNumber] = useState(0);

        useEffect(() => {
            const duration = 2000; // Animation duration in milliseconds
            const incrementTime = 50; // Time interval for each increment in milliseconds
            const totalSteps = duration / incrementTime; // Total number of steps
            const incrementValue = Math.ceil(targetNumber / totalSteps); // Value to increment at each step

            let count = 0;
            const interval = setInterval(() => {
                count += incrementValue;
                if (count >= targetNumber) {
                    clearInterval(interval);
                    count = targetNumber; // Ensure it ends on targetNumber
                }
                setCurrentNumber(count);
            }, incrementTime);

            return () => clearInterval(interval); // Cleanup on unmount
        }, [targetNumber]);

        const circleSize = 150; // Size of the circle
        const strokeWidth = 15; // Width of the circle's stroke
        const radius = (circleSize - strokeWidth) / 2; // Radius of the circle
        const circumference = 2 * Math.PI * radius; // Circumference of the circle
        const offset = circumference - (percentage / 100) * circumference; // Offset for stroke-dasharray

        return (
            <Box display="flex" flexDirection="column" alignItems="center" className="col">
                <Box position="relative" display="inline-block">
                    <svg width={circleSize} height={circleSize}>
                        <circle
                            stroke="#dadada"
                            fill="transparent"
                            strokeWidth={strokeWidth}
                            r={radius}
                            cx={circleSize / 2}
                            cy={circleSize / 2}
                        />
                        <circle
                            stroke={color}
                            fill="transparent"
                            strokeWidth={strokeWidth}
                            r={radius}
                            cx={circleSize / 2}
                            cy={circleSize / 2}
                            strokeDasharray={`${circumference} ${circumference}`}
                            strokeDashoffset={offset}
                            style={{ transition: 'stroke-dashoffset 0.5s ease-in-out' }}
                        />
                    </svg>
                    <Box position="absolute" top="50%" left="50%" style={{ transform: 'translate(-50%, -50%)' }}>
                        <Typography variant="h4" fontWeight="bold" style={{ color: '#000', fontFamily: 'Exo, sans-serif' }}>{currentNumber}</Typography>
                    </Box>
                </Box>
                <Typography variant="h6" style={{ marginTop: '10px', fontFamily: 'Exo, sans-serif' }}>{title}</Typography>
                <Typography style={{ color, fontFamily: 'Exo, sans-serif' }}>{percentage}%</Typography>
            </Box>
        );
    };

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 50);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // Button styles
    const buttonStyles = {
        fontFamily: 'Exo, sans-serif',
        fontWeight: 600,
        fontSize: '1rem',
        color: '#FFF',
        backgroundColor: '#DAB060',
        border: 0,
        padding: '8px 16px',
        position: 'relative',
        transition: 'transform 0.1s',
        '&:hover': {
            transform: 'translateY(-10%) scale(1.1)',
            boxShadow: '0 0 10px rgba(218, 176, 96, 0.5)',
        },
        '&:active': {
            transform: 'translateY(5%) scale(0.9)',
        },
    };

    const phoneNumber = "+91 8830385928";
    const emailAddress = "info@kisaanstar.com";
    const address = "4th floor, office number 401, Vishwaraj Pride, Nagar Rd, near hp petrol pump, Wagholi, Pune, Maharashtra 412207";

    return (
        <>
            {/* Header Section */}
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: scrolled ? '#445038' : 'transparent',
                    boxShadow: 'none',
                    zIndex: 1201,
                    transition: 'background-color 0.3s ease',
                }}
            >
                <Toolbar className="d-flex justify-content-between align-items-center" sx={{ maxWidth: '100%', padding: '0 20px' }}>
                    {/* Logo */}
                    <img src={Logo} alt="Logo" style={{ height: '60px', borderRadius: '20px', marginTop: '10px', marginBottom: '10px' }} />

                   {/* Navigation Menu */}
                   <nav className="d-none d-md-block">
                       <ul className="list-unstyled d-flex mb-0">
                           {['Home', 'About', 'Services', 'Contact'].map((item, index) => (
                               <li className="mx-3" key={index}>
                                   <Button
                                       color="inherit"
                                       sx={{
                                           color: scrolled ? '#fff' : '#000',
                                           fontFamily: 'Exo, sans-serif',
                                           '&:hover': {
                                               border: '2px solid #DAB060',
                                               borderRadius: '5px',
                                               boxShadow: '0 0 10px rgba(218, 176, 96, 0.5)',
                                           },
                                       }}
                                   >
                                       {item}
                                   </Button>
                               </li>
                           ))}
                           {/* Add the CRM link using Link */}
                           <li className="mx-3">
                               <Button
                                   component={Link} // Using Link
                                   to="/login" // Link to the login page
                                   color="inherit"
                                   sx={{
                                       color: scrolled ? '#fff' : '#000',
                                       fontFamily: 'Exo, sans-serif',
                                       '&:hover': {
                                           border: '2px solid #DAB060',
                                           borderRadius: '5px',
                                           boxShadow: '0 0 10px rgba(218, 176, 96, 0.5)',
                                       },
                                   }}
                               >
                                   CRM
                               </Button>
                           </li>
                       </ul>
                   </nav>

                    {/* Search Bar and Cart Icon */}
                    <div className="d-flex align-items-center">
                        <div className="position-relative" style={{ width: 'auto' }}>
                            <InputBase
                                placeholder="Search..."
                                sx={{
                                    border: '1px solid #FFF',
                                    borderRadius: '4px',
                                    padding: '2px 10px',
                                    backgroundColor: '#fff',
                                    minWidth: '150px',
                                    maxWidth: '250px',
                                    fontFamily: 'Inter, sans-serif', // Use Inter font for search input
                                }}
                            />
                            <IconButton type="submit" sx={{
                                position: 'absolute',
                                right: 0,
                                top: '50%',
                                transform: 'translateY(-50%)',
                                backgroundColor: '#DAB060',
                                '&:hover': {
                                    backgroundColor: '#c59b03',
                                }
                            }}>
                                <SearchIcon sx={{ color: '#445038' }} />
                            </IconButton>
                        </div>

                        {/* Shopping Cart Icon */}
                        <IconButton edge="end" color="inherit" sx={{ marginLeft: '10px', color: '#FFF' }}>
                            <ShoppingCartIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>

            {/* Hero Image Section with Overlay */}
            <div className="position-relative" style={{ overflow: 'hidden' }}>
                <img src={Hero} alt="Hero Background" className="img-fluid w-100" style={{ height: 'auto', maxHeight: '700px', objectFit: 'cover' }} />
                <div className="position-absolute top-50 start-0 translate-middle-y text-white p-4" style={{ zIndex: 1 }}>
                    <Typography
                        variant="h2"
                        style={{
                            fontFamily: 'Exo, sans-serif',
                            fontSize: '3.5rem',
                            fontWeight: 'bold',  // Text is bold
                            textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)', // Shadow effect
                            lineHeight: '1.2',
                            color: 'black', // Change title color to black
                            marginBottom:'10px'
                        }}>
                        Quality Agricultural
                    </Typography>
                    <h4 style={{ fontFamily: 'Exo, sans-serif', color: 'green' }}><b>Products for Every Farmer's Success</b></h4> {/* Subtitle */}
                    <Button
                        variant="contained"
                        sx={buttonStyles}
                        style={{
                            marginTop: '20px' // Adding margin to create space
                        }}>
                        Let's Connect
                    </Button>
                </div>
            </div>

            {/* 50-50 Grid Layout */}
            <div className="row no-gutters" style={{ width: '100%' }}>
                <div className="col-12 col-md-6 d-flex align-items-center justify-content-center p-3" >
                    <img src={vegis} alt='side image of vegis' className='img-fluid w-100' style={{ borderRadius: '50px' }} />
                </div>

                <div className="col-12 col-md-6 d-flex align-items-center justify-content-center p-3" >
                    <Box textAlign="left">
                        <h1 style={{ fontFamily: 'Exo, sans-serif', color: 'black' }}>Cultivating Nature's Bounty</h1>
                        <p style={{ fontFamily: 'Inter, sans-serif', color: 'black' }}> {/* Use Inter font for paragraph */}
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                        </p>
                        <Button variant="contained" sx={buttonStyles} style={{ marginRight: '10px' }}>Learn More</Button>
                        <Button variant="contained" sx={buttonStyles}>Connect Now!</Button>
                    </Box>
                </div>
            </div>

            {/* Services Section */}
            <Container className="text-center my-5" sx={{ backgroundColor: '#ECEEEB', borderRadius: '30px' }}>
                <Typography variant="h5" style={{ fontFamily: 'Exo, sans-serif' }}>Our Services</Typography>
                <Typography variant="h4" style={{ fontFamily: 'Exo, sans-serif' }}>What Do We Do?</Typography>
                <Typography style={{ fontFamily: 'Inter, sans-serif' }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus, ducimus.</Typography>

                <Grid container spacing={4} className="my-4">
                    {['Care For Plants', 'Soil Care', 'Guidance to Farmers'].map((service, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <Box>
                                <img src={Services2} alt='side image of services' className='img-fluid' style={{ borderRadius: '15px', marginBottom: '10px' }} />
                                <Typography variant="h6" style={{ fontFamily: 'Exo, sans-serif' }}>{service}</Typography>
                                <Typography style={{ fontFamily: 'Inter, sans-serif' }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus, ducimus.</Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            {/* Hover Carousel */}
            <div>
                <HoverCarousel images={images} sx={{ backgroundColor: '#ECEEEB' }} />
            </div>

            {/* Team Section */}
            <Container className="text-center my-5">
                <Typography variant="h5" style={{ fontFamily: 'Exo, sans-serif' }}>Our Team</Typography>
                <Typography variant="h4" style={{ fontFamily: 'Exo, sans-serif' }}>Meet Our Team</Typography>
                <Typography style={{ fontFamily: 'Inter, sans-serif' }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus, ducimus.</Typography>

                <Grid container spacing={4} className="my-4">
                    {[
                        { id: 1, name: "Advisor 1", image: Advisor },
                        { id: 2, name: "Advisor 2", image: Advisor },
                        { id: 3, name: "Advisor 3", image: Advisor1 },
                        { id: 4, name: "Advisor 4", image: Advisor1 }
                    ].map(({ id, name, image }) => (
                        <Grid item xs={12} md={3} key={id}>
                            <Box>
                                <img
                                    src={image}
                                    alt={name}
                                    className='img-fluid'
                                    style={{
                                        borderRadius: '15px',
                                        width: '100px',
                                        height: '100px',
                                        objectFit: 'cover' // Maintains aspect ratio and covers the area
                                    }}
                                />
                                <Typography variant="h6" style={{ fontFamily: 'Exo, sans-serif' }}>{name}</Typography>
                                <Typography style={{ fontFamily: 'Inter, sans-serif' }}>Lorem ipsum dolor sit amet consectetur adipisicing elit.</Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            {/* Products below */}
            <ProductList />

            {/* Counter Section */}
            <Container className="text-center my-5" sx={{ backgroundColor: '#ECEEEB', borderRadius: '30px' }}>
                <Typography variant="h4" style={{ marginBottom: '80px', paddingTop: '20px', fontFamily: 'Exo, sans-serif' }}>Our Achievements</Typography>
                <Grid container spacing={4}>
                    <Counter title="Our Clients" targetNumber={1500} percentage={90} color="#28a745" />
                    <Counter title="Our Services" targetNumber={25} percentage={50} color="#007bff" />
                    <Counter title="Our Targets" targetNumber={100} percentage={75} color="#ffc107" />
                    <Counter title="Our Products" targetNumber={500} percentage={60} color="#dc3545" />
                </Grid>
            </Container>

            {/* Contact Us Section */}
            <Container className="my-5">
                <div className="row">
                    <div className="col-md-6">
                        <Typography variant="h4" style={{ fontFamily: 'Exo, sans-serif' }}>Contact Us</Typography>
                        <form>
                            <TextField
                                fullWidth
                                label="Name"
                                variant="outlined"
                                margin="normal"
                                sx={{ fontFamily: 'Inter, sans-serif', marginBottom: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Email"
                                variant="outlined"
                                margin="normal"
                                sx={{ fontFamily: 'Inter, sans-serif', marginBottom: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Message"
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={4}
                                sx={{ fontFamily: 'Inter, sans-serif', marginBottom: 2 }}
                            />
                            <Button variant="contained" sx={buttonStyles} type="submit">Send Message</Button>
                        </form>
                    </div>

                    <div className="col-md-6">
                        <Typography variant="h4" style={{ fontFamily: 'Exo, sans-serif' }}>Company Information</Typography>
                        <div className="d-flex align-items-center mb-2">
                            <PhoneInTalkIcon />
                            <Typography
                                variant="body1"
                                sx={{ ml: 2, cursor: 'pointer' }}
                                style={{ fontFamily: 'Inter, sans-serif' }}
                                component="a"
                                href={`tel:${phoneNumber}`}
                            >
                                {phoneNumber}
                            </Typography>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <MailOutlineIcon />
                            <Typography
                                variant="body1"
                                sx={{ ml: 2 }}
                                style={{ fontFamily: 'Inter, sans-serif' }}
                                component="a"
                                href={`mailto:${emailAddress}`}
                            >
                                {emailAddress}
                            </Typography>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <PinDropIcon />
                            <Typography
                                variant="body1"
                                sx={{ ml: 2, cursor: 'pointer' }}
                                style={{ fontFamily: 'Inter, sans-serif' }}
                                onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`, '_blank')}
                            >
                                Address: {address}
                            </Typography>
                        </div>
                        <Typography variant="h4" style={{ fontFamily: 'Exo, sans-serif' }}>Follow Us</Typography>
                        <div className="d-flex justify-content-start">
    <IconButton
        aria-label="instagram"
        sx={{ color: '#C13584', margin: '0 5px', fontSize: '2rem' }}
        component="a"
        href="https://www.instagram.com/kisaanstar?igsh=YWs3d2V3MW5oejE"
        target="_blank"
        rel="noopener noreferrer"
    >
        <InstagramIcon fontSize="inherit" />
    </IconButton>
    <IconButton
        aria-label="youtube"
        sx={{ color: '#FF0000', margin: '0 5px', fontSize: '2rem' }}
        component="a"
        href="https://youtube.com/@kisaanstar?si=21s_5XXHiH7HwGdO"
        target="_blank"
        rel="noopener noreferrer"
    >
        <YouTubeIcon fontSize="inherit" />
    </IconButton>
    <IconButton
        aria-label="linkedin"
        sx={{ color: '#0077B5', margin: '0 5px', fontSize: '2rem' }}
        component="a"
        href="https://www.linkedin.com/company/kisaanstar"
        target="_blank"
        rel="noopener noreferrer"
    >
        <LinkedInIcon fontSize="inherit" />
    </IconButton>
</div>
                    </div>
                </div>
            </Container>

            {/* Footer Section */}
            <footer className="bg-white text-center py-4" style={{ backgroundColor: '#445038' }}>
                <Typography variant="body1" style={{ color: '#445038', fontFamily: 'Inter, sans-serif' }}>
                    © {new Date().getFullYear()} Kisaanstar PvtLtd - All Rights Reserved
                </Typography>
                <div className="d-flex justify-content-center">
                    <Button sx={{ ...buttonStyles, color: '#FFF' }}>Privacy Policy</Button>
                    <Button sx={{ ...buttonStyles, color: '#FFF', marginLeft: '20px' }}>Terms of Use</Button>
                </div>
            </footer>
        </>
    );
}

export default Home;